$(document).ready(function(){

    // Removed as it caused bounce.

    //$('.dropdown').hover(function(){
    //  $(this).find('.sub_items').slideToggle().css('display', 'flex');
    //});

    function setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      let expires = "expires="+ d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }

    if (!getCookie('accept')){
      $('.s-cookie').addClass('s-cookie--show');
    }

    $('.s-cookie__close').click(function(){
      $('.s-cookie').removeClass('s-cookie--show');
      setCookie('accept', 'accepted', 365);
    });

    $('.dropdown-mob button').click(function(){
      if ($('.sub_items').css('display') == 'none'){
        $('.sub_items').css('display', 'flex');
      } else {
        $('.sub_items').css('display', 'none');
      }
    });

    $('.mobile_toggle').click(function(){
        $('.nav').slideToggle();
    });

    $('.footer-block .block').each(function(){
      var it = $(this)
      var title = it.find('.title');
      title.click(function(){
        it.toggleClass('block--show');
      });
    });

    $(document).ready(function(){
      $('#enquiry form select option:first-child').attr('hidden', 'hidden').attr('disabled', 'disabled');
      $('#enquiry form select').change(function(){
          $(this).addClass('changed');
      });
  })

    $('#accreditations .items').slick({
        speed: 1000,
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplaySpeed: 5000,
        responsive: [
            {
              breakpoint: 1199,
              settings: {
                slidesToShow: 4,
              }
            },
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 3,
              }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    });

});